<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="custom-width">
            <!-- Brand logo-->
            <b-link class="brand-logo-update">
              <DefaultLogo />
            </b-link>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold bt-40">
              {{ $t("SetNewPassword") }}
            </b-card-title>

            <!-- form -->
            <validation-observer
              ref="forgotPasswordValidation"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- email -->
                <b-form-group :label="$t('New Password')">
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        name="password"
                        :type="passwordFieldType"
                        :placeholder="'Enter Password'"
                      />
                      <b-input-group-append
                        is-text
                        :class="errors.length ? 'is-valid' : 'is-invalid'"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('Confirm New Password')">
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    vid="ConfPassword"
                    rules="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="ConfPassword"
                        v-model="ConfPassword"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="ConfPassword"
                        placeholder="Enter Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <div class="recaptcha">
                  <re-captcha
                    :valid="valid"
                    :reset="reset"
                    @state="update($event)"
                    @rerender="handleRerender"
                  />
                  <small v-if="!valid || !captchaChecked" class="text-danger">{{
                    hasErrs
                  }}</small>
                </div>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading || !valid"
                >
                  {{ $t("login.ResetPassword") }}
                </b-button>
              </b-form>
            </validation-observer>
            <b-card-text class="text-center back-to-login">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Back to Home
              </b-link>
            </b-card-text>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthService from "@/libs/api/auth-service";
import ReCaptcha from "../../layouts/components/ReCaptcha.vue";
import LoginImage from "@/assets/images/pages/login-v2.svg";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,

    BInputGroupPrepend,
    ReCaptcha,
    LoginImage
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      ConfPassword: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      valid: false,
      reset: false,
      hasErrs: "",
      loading: false,
      captchaChecked: false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    update(value) {
      this.captchaChecked = true;
      this.valid = value;
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    handleRerender() {
      // Toggle the value to trigger a re-render of the child component
      this.reset = !this.reset;
    },
    async validationForm() {
      this.loading = true;
      if (!this.valid) {
        this.loading = false;
        return (this.hasErrs = this.$t("login.CaptchaError"));
      }

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      try {
        let response = await new AuthService().resetPassword({
          new_password: this.password,
          re_type_password: this.ConfPassword,
          code: code,
          step: 2,
          recaptcha_token: this.valid
        });

        if (response && response.data && response.data.success) {
          this.$router.push("/forgot-password-III");
        } else if (response && response.error && response.error.message) {
          this.captchaChecked = false;
          this.handleRerender();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$router.push("/").catch(() => {});
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.fill-primary {
  fill: var(--primary);
}
</style>
